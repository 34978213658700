@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: "AjannatLT";
  src: url("../public/alfont_com_Janna-LT-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes vibration {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(4px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(-4px);
  }
}

@keyframes scaleLetter {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
@keyframes rotateIn {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-90deg);
  }
}
@keyframes rotateOut {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes toast {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(40px);
    opacity: 0;
  }
}

.animate-vibrate {
  animation: vibration 0.8s ease-in;
}
.animate-letter {
  animation: scaleLetter 0.1s ease-in;
}
.animate-rotate-in {
  animation: rotateIn 0.35s ease-in;
}
.animate-rotate-out {
  animation: rotateOut 0.35s ease-in;
}
.animate-toast {
  animation: toast 0.2s ease-in;
}
.animate-slide-in {
  animation: slideIn 0.2s ease;
}
.animate-slide-out {
  animation: slideOut 0.2s ease;
}
